// Generated by Vue on Rails https://github.com/vueonrails/vueonrails
// Generate a component part like this by running command `rails generate vue something`
// Display this Vue component by adding `require('./player')` to app/javascript/packs/application.js

// import store from '@/store'

export default {
  data: function() {
    return {
      showInfo: false,
      videoPlaying: false,
      songData: null,
      playerVars: {
        autoplay: 1,
        rel: 0,
        mute: 0,
        playsinline: 1
      }
    };
  },
  watch: {
    onCreated: function(response) {
      this.items = response.data;
    }
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("Video started");
      this.showInfo = true
      this.videoPlaying = true
    },
    ended() {
      console.log('Done, get new movie');
      this.videoPlaying = false
      this.videoId = 'A'
      this.fetchNewSong();
    },
    paused() {
      this.videoPlaying = false;
    },
    error() {
      console.log('Error, get new movie');
      this.fetchNewSong();
    },
    updateTitle() {
      document.title = "LaksFM - " + this.songData.song_title + ' - ' + this.songData.artist_name
    },
    fetchSpecificSong(songId) {
      var obj;
      fetch(window.laksApiLocation.url + '/api/v1/song/' + songId)
      .then(response => response.json())
      .then(data => obj = data)
      .then(() => this.songData = obj['song'])
      .then(() => this.updateTitle())
      .catch(function() {
        // TODO: MAKE THIS BETTER

        console.log("Can't find this song. Might not exist?")
        alert("Can't find this song. Might not exist?")
      })
    },
    fetchNewSong() {
      var obj;
      fetch(window.laksApiLocation.url + '/api/v1/random')
      .then(response => response.json())
      .then(data => obj = data)
      .then(() => this.songData = obj['song'])
      .then(() => this.updateTitle())
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    }
  },
  created: function() {

    if (window.laksData) {
      const songid = window.laksData.song_id
      this.fetchSpecificSong(songid);
    } else {
      this.fetchNewSong();
    } 
  }
};
