// Generated by Vue on Rails https://github.com/vueonrails/vueonrails
// Display this Vue component on Rails View by adding `require('./player')` to app/javascript/application.js
// Generate a component part by running command `rails generate vue something`

import Vue from 'vue'
import App from '@/player/player.vue'
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(document.createElement('player'))
  const player = new Vue({
    render: h => h(App)
  }).$mount('player')
  console.log({player})
})